import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dataV from '@kjgl77/datav-vue3';
import moment from 'moment'
// 引入全局css
import './assets/scss/style.scss';
// 引入图表（所有图标见 icon 目录下的 demo_index.html）
import './assets/icon/iconfont.css'

// 引入 全局注册组件
import PublicComponent from '@/components/componentInstall';
import {Get} from "./utils/api-invoke";


import '@/components/SvgIcon/index.js'

import SvgIcon from '@/components/SvgIcon/index.vue'


//ElementPlus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

//WebGis
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

Number.prototype.toFixed = function (n) {
    if (n > 20 || n < 0) {  //精度允许0-20
        throw new RangeError('toFixed() digits argument must be between 0 and 20');
    }
    const number = this;
    if (isNaN(number) || number >= Math.pow(10, 21)) {
        return number.toString();
    }
    if (typeof (n) == 'undefined' || n == 0) {
        return (Math.round(number)).toString();
    }

    //判断是否为负数
    var isMinus = number > 0 ? false : true;

    let result = number.toString();
    const arr = result.split('.');

    // 整数的情况
    if (arr.length < 2) {
        result += '.';
        for (let i = 0; i < n; i += 1) {
            result += '0';
        }
        return result;
    }

    const integer = arr[0];
    const decimal = arr[1];
    //小数位数和精确位数相等时
    if (decimal.length == n) {
        return result;
    }
    //小数位数小于精确位数时
    if (decimal.length < n) {
        for (let i = 0; i < n - decimal.length; i += 1) {
            result += '0';
        }
        return result;
    }
    result = integer + '.' + decimal.substr(0, n);
    const last = decimal.substr(n, 1);

    // 如果需要进，放大转换为整数再处理，避免浮点数精度的损失
    if (parseInt(last, 10) >= 5) {
        const x = Math.pow(10, n); //放大系数
        // 对于过精度位的下一位值大于5时，正数+1 负数-1.
        // 正数例如1.057 转化为两位精度的小数是 1.06。
        // 负数例如-1.057 转化为两位精度的小数是 -1.06。
        result = (Math.round((parseFloat(result) * x)) + (isMinus ? -1 : 1)) / x;
        //为避免除以系数后小数部分0缺失，再调用一次方法。
        //如1.299，保留两位小数，（1.29*100 + 1）/100=1.3，则需要再补一次零->1.30
        result = result.toFixed(n);
    }
    return result;
};
router.beforeEach(async (to, from, next) => {
    // @ts-ignore
    window.document.title = (to.meta && to.meta.title) ? to.meta.title : '数据大屏'
    if (to.name === 'Login') {
        next()
    } else {
        let token = store.getters.token
        if (token) {
            if (!store.getters.user) {
                await store.dispatch('loadUserInfo')
            }
            store.dispatch('loadFactoryInfo')
            store.dispatch('loadFactoryWarnings')
            next()
        } else {
            next(`/login?redirect=${to.path}`)
        }
    }
})

moment.locale('zh-cn')

const app = createApp(App)
app.use(PublicComponent)
app.use(dataV)
app.use(store)
app.use(router)
app.use(L)
app.use(ElementPlus)
app.component('svg-icon', SvgIcon)
//store.dispatch('initLoad')
app.mount('#app')


