import {createStore} from 'vuex'
import {Get, Post} from "../utils/api-invoke";
import store from "./index";
import session from '@/utils/session'
import {ElMessageBox} from "element-plus";
import {
    getFactoryBreeds,
    getFactoryDevices,
    getFactoryDormitories,
    getFactoryWarningCount,
    getWarningDetail
} from "../utils/capsule";
import moment from "moment";

const getUserInfo = () => {
    let sessionInfo = session.get('UserInfo')
    if (sessionInfo && sessionInfo !== 'undefined' && sessionInfo !== 'null') {
        return sessionInfo
    }
    return null
}
const getUserAreaInfo = () => {
    let sessionInfo = session.get('AreaInfo')
    if (sessionInfo && sessionInfo !== 'undefined' && sessionInfo !== 'null') {
        return sessionInfo
    }
    return null
}
const getFactoryInfo = () => {
    let sessionInfo = session.get('FactoryInfo')
    if (sessionInfo && sessionInfo !== 'undefined' && sessionInfo !== 'null') {
        return sessionInfo
    }
    return null
}

interface LoginInfoType {
    username: string,
    password: string,
    catpchaCode: string,
    uuid: string
}

let warningTimer = null
// @ts-ignore
export default createStore({
    state: {
        token: session.get('AuthLogin'),
        userInfo: getUserInfo(),
        currentLocation: () => {
            let user = store.state.userInfo
            return user ? user.areaCode : '530000'
        },
        factory: {
            total: 0,   //存栏
            devices: 0, //设备量
            dormitory: 0,   //棚舍数量
        },
        dormitory: [],  //棚舍数据,
        warnings: [],   //报警信息
    },
    getters: {
        token: (state) => state.token,
        user: (state) => state.userInfo,
        location: (state) => state.currentLocation,
        factory: (state) => state.factory,
        dormitory: (state) => state.dormitory,
        warnings: (state) => state.warnings
    },
    mutations: {
        RESET(state) {
            state.token = null
            state.userInfo = null
        },
        async SET_TOKEN(state, token) {
            session.set('AuthLogin', token)
            state.token = token
        },
        async SET_USER_INFO(state, userInfo) {
            if (userInfo != null) {
                session.set('UserInfo', userInfo)
                state.userInfo = userInfo
            }
        },
        SET_CURRENT_LOCATION(state, code) {
            state.currentLocation = code
        },
        SET_FACTORY_INFO(state, value) {
            Object.keys(value).forEach(k => {
                state.factory[k] = value[k]
            })
        },
        /**
         * 设置棚舍信息
         * @param state
         * @param value
         * @constructor
         */
        SET_DORMITORY(state, value) {
            state.dormitory = value
        },
        /**
         * 设置报警信息
         * @param state
         * @param value
         * @constructor
         */
        SET_WARNINGS(state, value) {
            state.warnings = value
        }
    },
    actions: {
        /**
         * 初始化读取
         */
        initLoad({commit}) {
            commit('SET_USER_INFO', getUserInfo())
        },
        /**
         * 重置session
         * @param commit
         */
        resetSession({commit}) {
            session.reset()
            commit('RESET')
        },
        /**
         * 设置当前区域代码
         * @param commit
         * @param code
         */
        setCurrentLocation({commit}, code) {
            commit('SET_CURRENT_LOCATION', code)
        },
        /**
         * 任务启动，读取告警信息
         * @param commit
         */
        async _loadFactoryWarnings({commit}) {

            let warningList = []
            if (store.getters.user && store.getters.user.factoryCode) {
                let fid = store.getters.user.factoryCode
                let warnings = await getWarningDetail({
                    status: 1,
                    parentId: fid,
                    pageNum: 1,
                    pageSize: 10,
                    //startTime: moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
                    endTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                })

                if (warnings && warnings['code'] && warnings['code'] === 200 && warnings['rows'] && warnings['rows'].length > 0) {
                    let rows = warnings['rows']
                    let earcons = []
                    rows.forEach(row => {
                        //console.info('WARNING:', row)
                        if (!earcons.includes(row.earconNum)) {
                            earcons.push(row.earconNum)
                        }
                    })
                    let cattleInfo = {}
                    //按耳标号读取牛只信息
                    for (const ec of earcons) {
                        let cattle = await getFactoryBreeds(fid, 1, 1, null, ec)
                        if (cattle && cattle['code'] && cattle['rows'] && cattle['rows'].length > 0) {
                            let info = cattle['rows'][0]
                            cattleInfo[ec] = info
                        }
                    }
                    //置入信息
                    rows.forEach(row => {
                        let num = row.earconNum
                        row.info = cattleInfo[num]
                        //TODO: 重置所有状态为已处置
                        row.status = 3
                    })
                    warningList = rows
                }

            }
            commit('SET_WARNINGS', warningList)
        },
        /**
         * 读取牛只报警信息
         * @param commit
         */
        async loadFactoryWarnings({commit}) {
            store.dispatch('_loadFactoryWarnings')
            if(!warningTimer) {
                warningTimer = setInterval(() => {
                    store.dispatch('_loadFactoryWarnings')
                }, 30000)
            }
        },
        /**
         * 取牛场信息
         * @param commit
         */
        async loadFactoryInfo({commit}) {
            const setNum = (data, key) => {
                if (data && data['code'] && data['code'] === 200) {
                    let p = {}
                    p[key] = data['total']
                    commit('SET_FACTORY_INFO', p)
                }
            }
            if (store.getters.user.factoryCode) {
                //console.info('############factory', store.getters.user.factoryCode)
                let fid = store.getters.user.factoryCode
                let dormitories = await getFactoryDormitories(fid)
                setNum(dormitories, 'dormitory')
                commit('SET_DORMITORY', dormitories['rows'])

                let total = await getFactoryBreeds(fid, 1, 1)
                setNum(total, 'total')

                let devices = await getFactoryDevices(fid, 1, 1)
                setNum(devices, 'devices')
            }
        },
        /**
         * 用户登录
         * @param commit
         * @param loginInfo
         */
        async userLoginAuth({commit}, loginInfo: LoginInfoType) {
            return new Promise((resolve, reject) => {
                Post('/auth/login', loginInfo).then(async res => {
                    localStorage.setItem('USER_NAME', loginInfo.username)
                    await commit('SET_TOKEN', res['token'])
                    resolve(res)
                }).catch(ex => {
                    reject(ex || '登录出错')
                })
            })
        },


        /**
         * 读取用户信息
         * @param commit
         */
        async loadUserInfo({commit}) {
            return new Promise((resolve, reject) => {
                Get('/user/info', {}).then(async res => {
                    if (res) {
                        await commit('SET_USER_INFO', res)
                        resolve(res)
                    } else {
                        await ElMessageBox.alert('获取用户信息失败')
                        reject('获取用户信息失败')
                    }
                }).catch(err => {
                    console.error(err)
                    ElMessageBox.alert('获取用户信息出错')
                    reject('获取用户信息出错')
                })
            })

        }
    },
    modules: {}
})
